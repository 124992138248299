import React, { useState } from "react";
import "../css/Header.css";
import "./index.css";
import { Link } from "react-router-dom";
import FullLogoLight from "../../assets/Images/FullLogoLight.png"
import { Row, Col } from "reactstrap";


// import Industries from "./Submenus/Industries";
// import Abouts from "./Submenus/About";
// import Services from "./Submenus/Services";
// import Resources from "./Submenus/Resources";
import UserDropdowns from "./UserDropdowns";



var $ = require("jquery");

function Header() {
  $(function () {
    var header = $(".start-style");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 10) {
        header.removeClass("start-style").addClass("scroll-on");
      } else {
        header.removeClass("scroll-on").addClass("start-style");
      }
    });
  });

  //Animation

  // $(document).ready(function () {
  //   $("body.hero-anime").removeClass("hero-anime");
  // });

  //Menu On Hover

  $("body").on("mouseenter mouseleave", ".nav-main-item", function (e) {
    // if ($(window).width() > 750) {
    var _d = $(e.target).closest(".nav-main-item");
    _d.addClass("show");
    setTimeout(function () {
      _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
    }, 1);
    // }
  });

  $("body").on("click", ".dropdown-item", function (e) {
    var _d = $(e.target).closest(".nav-main-item");
    _d.removeClass("show");
    if ($(window).width() <= 750) {
      toggleMenu()
    }

  });

  const [state, setState] = useState({
    menu: false,
    isOpen: false,
    homeLinkClass: "nav-main-item nav-link",
    aboutLinkClass: "nav-main-item nav-link",
    menuClass: "",
  });

  const toggleMenu = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };

  const toggleOpen = () => setState({ ...state, isOpen: !state.isOpen });

  const show = state.menu ? "show" : "";
  const menuClass = `dropdown-menu${state.isOpen ? " show" : ""}`;


  return (
    <div className="navigation-wrap bg-light start-header start-style">
      <div className="container">
        <Row>
          <Col lg={12}>
            <nav className="navbar navbar-expand-md navbar-light p-0">
              <Link to="/" className="header-navbar-brand">
                <img src={FullLogoLight} alt="/" />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={`collapse navbar-collapse ${show} nav_collapsed_box`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class">
                    <Link
                      to="/contacts"
                      className="nav-main-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      Contacts
                    </Link>
                    {/* <div className="dropdown-menu nav_dropdowns_menu navbar_sub_menu_main">
                      <Abouts />
                    </div> */}
                  </li>
                  <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4">
                    <Link to="/job-applicants" className="nav-main-link">
                      Job applicants
                    </Link>
                  </li>
                  <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class">
                    <Link
                      to="/services"
                      className="nav-main-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      Blogs
                    </Link>
                    {/* <div className="dropdown-menu nav_dropdowns_menu navbar_sub_menu_main">
                      <Services />
                    </div> */}
                  </li>
                  <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4">
                    <Link to="/jobs" className="nav-main-link">
                      Jobs
                    </Link>
                    {/* <div className="dropdown-menu nav_dropdowns_menu navbar_sub_menu_main">
                      <Industries />
                    </div> */}
                  </li>
                  {/* <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4">
                    <Link to="/contact" className="nav-main-link">
                      Contact
                    </Link>
                  </li> */}
                  <li className="nav-main-item pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class">
                    <UserDropdowns />
                  </li>
                </ul>
              </div>
            </nav>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Header;
