import { Route, Routes, useNavigate } from "react-router-dom";
import { publicRoutes, routes } from "./Routes";
import AuthCheckRoutes from "./CheckAuth";
// import Layout from "../Layout";
import NonAuthLayout from "../Layout/NonAuthLayout";
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';
export default function AppRoutes() {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  if(token) {
    const { exp } = jwt_decode(token);
    const expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      toast.error("Sorry. your session has expired. Please Login again!");
      localStorage.clear();
      navigate("/signin")
    }
  }
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, idx) => (
          <Route
            key={idx}
            path={path}
            element={<AuthCheckRoutes>{Component}</AuthCheckRoutes>}
          />
        ))}
        {publicRoutes.map(({ path, Component }, idx) => (
          <Route
            key={idx}
            path={path}
            authProtected={false}
            element={<NonAuthLayout>{Component}</NonAuthLayout>}
          />
        ))}
      </Routes>
    </>
  );
}
