import Home from "../pages/Home/Home";
import NotFound from "../constants/NotFound/NotFound";
import Login from "../authentication/Login/Login";
import Register from "../authentication/Registration/Registration";
import Contacts from "../pages/Contacts/Contacts";
// import Register from "../authentication/Registration/Registration";

export const routes = [
    { path: "/", Component: <Home /> },
    { path: "/contacts", Component: <Contacts /> },

];

export const publicRoutes = [
  { path: "*", Component: <NotFound /> },
  { path: "/signup", Component: <Register /> },
  { path: "/signin", Component: <Login /> },
  
];
