import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/reset.css";
// import "react-phone-input-2/lib/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackToTopButton from "./Layout/Scroll/ScrollToTop";

function App() {

  const currentUrl = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUrl]);

  return (
    <div className="App">
      <BackToTopButton />
      <Router>
        <AppRoutes />
        <Toaster position="top-right" reverseOrder={false} />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </div>
  );
}

export default App;
