import React from "react";
import "./footer.css";
import { AiFillInstagram} from "react-icons/ai";
import { BsTwitter} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <React.Fragment>
      <section className="globalbgcolor">
        <div className="capeark-bottom-footer">
          <div className="container capeark-bottom-footer-cont">
            <div className="row">
              <div className="bottom-footer-terms-cont col-sm-12 col-md-6 col-lg-6">All rights reserved by Capeark. Copyright ©2023</div>
              <div className="bottom-footer_social_cont col-sm-12 col-md-6 col-lg-6">
                <Link to="#" className="bottom-footer_social_icon_cont"> <BsTwitter /></Link>
                <Link to="#" className="bottom-footer_social_icon_cont"> <GrLinkedinOption /></Link>
                <Link to="#" className="bottom-footer_social_icon_cont"><AiFillInstagram /></Link>
                <Link to="#" className="bottom-footer_social_icon_cont"> <FaFacebookF /> </Link>
              </div>
            </div>
          </div>
        </div>
      </section >
    </React.Fragment >
  );
}



