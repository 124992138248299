import callApi from "../../callApi";
import { POST, GET} from "../../constants/methods";


async function getId() {
  return localStorage.getItem("user_id");
}


export const signup = async (data) => {
  return await callApi("/api/users/signup", data, POST);
};
export const signin = async (data) => {
  return await callApi("/api/users/signin", data, POST);
};

export const verifyAccount = async (id, code, data) => {
  return await callApi(`/users/authorisation/activate/${id}/?code=${code}`, data, GET);
};
