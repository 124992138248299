import React, { useEffect, useRef, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

function Layout({ children }) {
  const toggle = useRef();
  const nav = useRef();
  const bodypd = useRef();
  const headerpd = useRef();
  const showNavbar = () => {
    // show navbar
    nav.current.classList.toggle("show");
    // change icon
    toggle.current.classList.toggle("bx-x");
    // add padding to body
    // bodypd.current.classList.toggle("container-pd");
    // add padding to header
    headerpd.current.classList.toggle("body-pd");
  };

  return (
    <React.Fragment>
      <div className="main_page_cont">
        <Header headerpd={headerpd} toggle={toggle} showNavbar={showNavbar} />
        <div className="bg-white">{children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Layout;
