import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { toast } from "react-toastify";
import "../css/index.css";
import LogoIcon from "../../assets/Images/LogoIcon.png";
import FullLogoLight from "../../assets/Images/FullLogoLight.png";
import { signin } from "../../helpers/dataFetcher/Profile/Profile";
function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [errorCounter, setErrorCounter] = useState(null);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // Login function
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (loginData?.email === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (loginData?.password === "") {
      setLoad(false);
      setError(true);
      return;
    }
    const { status, data } = await signin(loginData);
    if (status === 201) {
      toast.success("Signin successfully");
      navigate("/");
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem("user_info", JSON.stringify(data?.data?.user));
      setLoad(false);
    }
    setErrorCounter(5);
    setLoad(false);
    return;
  };

  useEffect(() => {
    const timer =
      errorCounter > 0 &&
      setInterval(() => setErrorCounter(errorCounter - 1), 1000);
    if (errorCounter === 0) {
      setLoad(false);
    }
    return () => clearInterval(timer);
  }, [errorCounter]);


  return (
    <React.Fragment>
      <div className="auth__main_cont">
        <div className="auth__main_left">
          <div className="auth__main_logo_cont">
            <div className="auth__main_logo_div">
              <img src={LogoIcon} alt="" />
            </div>
            <div className="auth__main_left_blur_cont"></div>
          </div>
        </div>
        <div className="auth__main_right">
          <div className="auth__right_action_top">
            <div></div>
            <div>
              <img src={FullLogoLight} alt="" />
            </div>
          </div>
          <div className="auth__right_action_middle pl-3 pr-3">
            <h4>Welcome back</h4>
            <p>Welcome back! Please enter your details.</p>
            <Form onSubmit={onSubmit} className="auth__right_action_middle_form">
              <div className="row g-0">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <FormGroup>
                    <Label for="email" className="auth_login_input_lebal">
                      Email <span className="required_star_error">*</span>
                    </Label>
                    <Input
                      invalid={loginData?.email === "" && error}
                      className="auth_login_input_box"
                      onChange={(e) =>
                        setLoginData({ ...loginData, email: e.target.value })
                      }
                      name="email"
                      placeholder="Enter your email"
                    />
                    <FormFeedback
                      invalid={loginData?.email === "" && error}
                      className="auth_login_feedback_text"
                    >
                      This field is required.
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <FormGroup>
                    <Label for="password" className="auth_login_input_lebal">
                      Password <span className="required_star_error">*</span>
                    </Label>
                    <Input
                      invalid={loginData?.password === "" && error}
                      className="auth_login_input_box"
                      onChange={(e) =>
                        setLoginData({ ...loginData, password: e.target.value })
                      }
                      type="password"
                      name="password"
                      placeholder="Enter your password"

                    />
                    <FormFeedback
                      invalid={loginData?.password === "" && error}
                      className="auth_login_feedback_text"
                    >
                      This field is required.
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Link to="#" className="auth_forget_pass_text">Forget password</Link>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Button
                    onClick={(e) => onSubmit(e)}
                    disabled={load}
                    type="submit"
                    className="auth_button_main mt-2 mb-2"
                  >
                    Sign in
                  </Button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 auth_redirect_signup_text mt-3 mb-3" >
                  Don't have an account? <Link to="/signup">Sign up</Link>
                </div>
              </div>

            </Form>
          </div>
          <div className="auth__right_action_bottom">
            © Capeark 2023
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
