import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import user_icon from "../../assets/Images/Navbar/user_icon.svg";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import "./index.css";

export default function UserDropdowns() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function getUser() {
    return JSON.parse(localStorage.getItem("user_info"));
  }
  const navigate = useNavigate();
  function LogoutNow() {
    toast.success("Logout Successfull");
    localStorage.clear();
    navigate("/signin");
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip placement="top" title={getUser()?.fname} arrow>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "50%",
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img src={user_icon} alt="" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            minWidth: "200px",
            maxWidth: "250px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="top_user_informatiom_main">
          <div className="top_user_informatiom_avatar">
            {getUser()?.logo ? (
              <img src={getUser()?.logo} alt="" />
            ) : (
              <img src={user_icon} alt="" />
            )}
          </div>
          <div className="text-truncate top_user_informatiom_name">
            <span className="text-truncate">
              {getUser()?.name}
            </span>
          </div>
        </div>
        <Divider />
        <MenuItem
          onClick={() => {
            LogoutNow();
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
