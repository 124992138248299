import React, { useEffect } from 'react'
import "./Home.css";
import LogoIcon from "../../assets/Images/LogoIcon.png";
function Home() {
  const currentUrl = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUrl]);
  return (
    <div className='home_main_cont'>
         <div>
              <img src={LogoIcon} alt="" />
            </div>
    </div>
  )
}

export default Home