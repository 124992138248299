import React from 'react';
import "./index.css";
import { Button } from 'reactstrap';
import MainLoader from '../../../helpers/loader/Loader';
import { msToTime } from '../../../helpers/ConvertUnit';
function ContactCard({ contactData, load }) {

    function getDateCount(date) {
        let date1 = new Date().toISOString();
        const currentDate = new Date(date1);
        const createdDate = new Date(date);
        const diffTime = Math.abs(currentDate - createdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffTime < 86400000) {
            return msToTime(diffTime);
        } else {
            return diffDays + " days";
        }
    }

    function getData(date) {
        const created_at = new Date(date);
        const readableDate = created_at.toLocaleString();
        return readableDate
    }

    return (
        <React.Fragment>
            <div className='contact__card_main'>
                {load ? (
                    <MainLoader />
                ) : (
                    <>
                        {contactData && contactData.length === 0 ? (
                            <>
                                <div>No contact yet</div>
                            </>
                        ) : (
                            <>
                                {contactData?.map((item, index) => (
                                    <div className='contact__card_main_cont'>
                                        <div className='contact__card_main_info'><span>Connected at {getDateCount(item?.created_at)} ago</span> &#x2022; <span>{getData(item?.created_at)}</span></div>
                                        <div className='contact__card_main_name'>
                                            <div><p><span># First name :</span> {item?.first_name}</p></div>
                                            <div><p><span># Last name :</span>  {item?.last_name}</p></div>
                                        </div>
                                        <div className='contact__card_main_other_details'>
                                            <p><span># Email :</span>  {item?.email}</p>
                                            <p><span># Phone :</span>  {item?.phone}</p>
                                            <p><span># Work for (Company) :</span>  {item?.company}</p>
                                            <p><span># Contact About :</span>  {item?.contact_about}</p>
                                            <p><span># Hear about us through :</span> {item?.hear_through}</p>
                                        </div>
                                        <div className='contact__card_main_message_cont'>
                                            <span># Message </span>
                                            <p>
                                                {item?.message}
                                            </p>
                                        </div>
                                        <div className='contact__card_main_action_cont'>
                                            <Button className='mr-2 contact__card_edit_btn' color="success">Edit</Button>
                                            <Button color="danger" className='contact__card_delete_btn'>Delete</Button>
                                        </div>

                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    )
}

export default ContactCard;