import React, { useEffect, useState } from 'react';
import "./index.css";
import ContactCard from './Pages/ContactCard';
import { getAllContact } from '../../helpers/dataFetcher/Contact/Contact';

function Contacts() {

    const [load, setLoad] = useState(false);
    const [contactData, setContactData] = useState([]);
    const currentUrl = window.location.pathname;

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [currentUrl]);
    useEffect(() => {
        getAllContactInfo();
    }, [])

    async function getAllContactInfo() {
        setLoad(true);
        const { status, data } = await getAllContact();
        if(status === 200) {
            setLoad(false);
        }
        setContactData(data?.data);
        setLoad(false);
    }

  return (
    <React.Fragment>
        <div className='contacts__page_main'>
            <div className='contacts__page_left_main'>

            </div>
            <div className='contacts__page_right_main'>
                <ContactCard contactData={contactData} load={load} />
            </div>
        </div>
    </React.Fragment>
  )
}

export default Contacts;